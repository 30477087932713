import React from "react";
import tw from "twin.macro";
import { Link } from "gatsby";
import CaseResultsCard from "./CaseResultsCard";

const Section = tw.section`grid w-full grid-cols-1 lg:grid-cols-2 gap-10 px-6 md:px-12 2xl:px-24 pb-24 pt-8 md:pt-0`;

const CaseResultCards = ({ cards, ...rest }) => {
  return (
    <Section {...rest}>
      {cards.map((card, i) => (
        <CaseResultsCard {...card} key={i} />
      ))}
    </Section>
  );
};

export default CaseResultCards;
