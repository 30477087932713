import React, { useState } from "react";
import tw, { styled } from "twin.macro";

import BigCircle from "./BigCircle";

const Hero = tw.section`bg-dark relative flex flex-row items-center px-6 justify-center w-full max-h-screen min-h-screen overflow-hidden md:-mb-24 z-behind`;
const FeaturedCase = tw.div`flex flex-col text-white font-mont absolute items-center max-w-[60rem] px-8`;
const Result = tw.p` font-bold text-7xl md:text-8xl xl:text-[8.5rem] leading-none mb-4`;
const Title = tw.h1`flex flex-row text-center font-mazuis italic text-4xl xl:text-5xl mb-10 sm:mb-24`;
const Description = tw.p`text-lg md:text-xl xl:text-2xl leading-relaxed text-center`;
const Video = styled.video(({ videoLoading }) => [
  tw`z-behind blur-0 absolute top-0 left-0 object-cover w-full h-full duration-700 ease-in-out opacity-100`,
  !!videoLoading && tw`blur-md opacity-0`,
]);

const InnerHero = ({
  amount,
  description,
  magnitude,
  title,
  video,
  ...rest
}) => {
  const [videoLoading, setVideoLoading] = useState(true);

  return (
    <Hero {...rest}>
      {typeof window !== "undefined" && (
        <Video
          autoPlay
          onLoadedData={() => setVideoLoading(false)}
          videoLoading={videoLoading}
          loop
          muted
          playsInline
          id="vid"
          width="100%"
          height="100%"
        >
          <source src={video} type="video/mp4" />
        </Video>
      )}
      <BigCircle />
      <FeaturedCase>
        <Result>
          ${amount}
          <span tw="uppercase text-primary">{magnitude}</span>
        </Result>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </FeaturedCase>
    </Hero>
  );
};

export default InnerHero;
