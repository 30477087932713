/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import InnerHero from "../components/InnerHero";
import CaseResultCards from "../components/CaseResultCards";

const Page = ({ data }) => {
  const { wpPage: page } = data;
  const { ihFeaturedCase, ihBackgroundVideo } = page.innerHero;
  const { caseResultCards } = page.caseResultsCards;

  return (
    <Layout caseResults seo={page.seo}>
      <InnerHero video={ihBackgroundVideo} {...ihFeaturedCase} />
      <CaseResultCards cards={caseResultCards} />
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      innerHero {
        ihFeaturedCase {
          amount
          description
          magnitude
          title
        }
        ihBackgroundVideo
      }
      caseResultsCards {
        caseResultCards: crCardsCaseResults {
          amount
          description
          title
          link {
            ...Button
          }
        }
      }
    }
  }
`;
