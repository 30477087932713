import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import anime from "animejs";
import tw from "twin.macro";
import { Link } from "gatsby";

const Card = tw(
  Link
)`flex flex-col bg-white relative duration-500 h-full items-center justify-center py-16 px-4 shadow-xl hover:(scale-105 shadow-2xl) before:(content[''] bg-primary w-full absolute h-4 top-0 left-0)`;

const Amount = tw.p`font-bold text-3xl sm:text-4xl text-primary mb-2 uppercase text-center`;
const Title = tw.p`font-mazuis italic text-xl sm:text-2xl text-center`;
const Description = tw.p`text-center sm:text-lg leading-loose mt-12 max-w-[35rem]`;

const CaseResultsCard = ({ title, amount, description, link, ...rest }) => {
  const { ref: observer, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const cardRef = useRef();

  useEffect(() => {
    if (inView) {
      anime({
        targets: cardRef.current,
        translateY: [50, 0],
        opacity: ["0%", "100%"],
        duration: 1000,
        easing: "easeInOutCubic",
      });
    }
  }, [inView]);

  return (
    <div ref={cardRef} tw="translate-y-[50px] opacity-0">
      <span ref={observer}>
        <Card
          {...rest}
          to={link?.url ? link.url : "#"}
          target={link?.target ? link.target : null}
        >
          <Amount>{amount}</Amount>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Card>
      </span>
    </div>
  );
};

export default CaseResultsCard;
